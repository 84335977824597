/**
 * Available level statuses.
 */
export enum CookieOrigin {
  PLACEHOLDER = 'placeholder',
  LEVEL = 'level',
}

/**
 * Cookie of level with details.
 */
export default class Cookie {
  /**
   * Unique ID of cookie.
   */
  public id: string;

  /**
   * Type of cookie.
   */
  public type: string;

  /**
   * X position of cookie in level.
   */
  public x: number;

  /**
   * Y position of cookie in level.
   */
  public y: number;

  /**
   * Line number.
   */
  public zOrder: number;

  /**
   * Origin of cookie.
   */
  public origin: CookieOrigin;


  /**
   Initializes cookie of level with given required properties.

   @param type    Type of cookie.
   @param x       X position of cookie in panel.
   @param y       Y position of cookie in panel.
   @param z       zOrder of cookie.
   @param origin  Origin of cookie.
   @param id      Unique ID of cookie.
  */
  public constructor (
    type: string,
    x: number,
    y: number,
    z: number,
    origin: CookieOrigin = CookieOrigin.LEVEL,
    id?: string,
  ) {
    this.type = type
    this.x = x
    this.y = y
    this.zOrder = z
    this.origin = origin
    this.id = id === undefined
      ? (Math.random() + 1).toString(36).substring(3)
      : id
  }

  public getObjectType () {
    switch (this.type) {
      case 'cooki_0.png':
        return 'BarTime'
      case 'cooki_1.png':
        return 'Normal'
      case 'cooki_2.png':
        return ''
      case 'cooki_3.png':
        return ''
      case 'cooki_4.png':
        return ''
      case 'cooki_5.png':
        return 'Freeze'
      case 'cooki_6.png':
        return 'Blaze'
      case 'cooki_7.png':
        return 'Strong'
      case 'cooki_8.png':
        return 'Jelly'
      case 'cooki_9.png':
        return 'CrystalWithTime'
      case 'cooki_10.png':
        return 'Teleport'
      case 'star_in game.png':
        return 'Crystal'
    }
    console.error(`Unknown object type for type ${this.type}`)
    return 'null'
  }

  public getElementMatrix () {
    const element = document.querySelector(`.cookie[data-id="${this.id}"]`)
    if (!element) {
      return null
    }

    const style = window.getComputedStyle(element)
    return new WebKitCSSMatrix((style as any).transform)
    // console.log('matrix: ', matrix)
    // element.style.transform = 'translate(0, 0)'
    // console.log('translateX: ', matrix.m41)
    // console.log('translateY: ', matrix.m42)
  }

  public getNewX () {
    const matrix = this.getElementMatrix()
    return matrix
      ? this.x + matrix.m41
      : 0
  }

  public getNewY () {
    const matrix = this.getElementMatrix()
    return matrix
      ? this.y - matrix.m42
      : 0
  }

  public getObjectName () {
    switch (this.type) {
      case 'cooki_0.png':
        return 'speed.png'
      case 'cooki_1.png':
        return 'cooki1.png'
      case 'cooki_2.png':
        return ''
      case 'cooki_3.png':
        return ''
      case 'cooki_4.png':
        return ''
      case 'cooki_5.png':
        return 'molniya.png'
      case 'cooki_6.png':
        return 'bomb.png'
      case 'cooki_7.png':
        return 'ironblock.png'
      case 'cooki_8.png':
        return 'marmelad.png'
      case 'cooki_9.png':
        return 'time.png'
      case 'cooki_10.png':
        return 'teleport.png'
      case 'star_in game.png':
        return 'star_in_game.png'
    }
    console.error(`Unknown name for type ${this.type}`)
    return 'null'
  }
}
