import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import ApiRequest from '@/core/ApiRequest'
import { GET_API_REQUEST } from '@/store/root/getters'


@Component
export default class GameConstantsHistory extends Vue {
  public history: Array<any> = []

  /**
   * Property that indicates revision of shown level.
   */
  @Prop({ default: null })
  public revision_id: string | null;

  /**
   * Get api request object, based on global Vuex state.
   */
  @Getter(GET_API_REQUEST)
  public getApiRequest: ApiRequest

  /**
   * Fetch history.
   */
  public async fetchHistory () {
    const response = await this.getApiRequest.send(
      'GET',
      'constants/history',
    )
    if (!response || !response.data) { return }

    this.history = response.data
  }

  /**
   * Format date to local format.
   */
  public formatDate (date: string): string {
    return (new Date(date)).toLocaleDateString() + ' ' + (new Date(date)).toLocaleTimeString()
  }

  /**
   * Navigate to given revision.
   */
  public navigateToRevision (revisionId: string): void {
    const href = this.$router.resolve({ name: 'gameConstants', params: { revision: revisionId } }).href
    window.location.assign(href)
  }

  /**
   * Get formatted field.
   *
   * @param field  Field name.
   */
  public getFormattedField (field: string): string {
    const fieldNames: any = {
      comments: 'Комментарии',
    }
    return fieldNames[field] || ''
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    await this.fetchHistory()
  }
}
