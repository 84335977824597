/**
 * Describes values for user role.
 */
export enum Role {
  administrator = 'administrator',
}

/**
 * Application user.
 */
export default class User {
    /**
     * User id.
     */
    public id: string = '';

    /**
     * User short name.
     */
    public username: string = '';

    /**
     * User full name.
     */
    public fullname: string = '';

    /**
     * User email.
     */
    public email: string = '';

    /**
     * User role.
     */
    public role: string | null = null;

    /**
     * User status.
     */
    public status: string = '';

    /**
     * User password.
     */
    public password: string | null = null;

    /**
     * User created date.
     */
    public created_at: any = 'null';
}
