/**
 * Available level statuses.
 */
export enum LevelStatus {
  INPROGRESS = 'inprogress',
  COMPLETED = 'completed',
}

/**
 * Level with details.
 */
export default class Level {
  /**
   * Unique ID.
   */
  public uq_id: string;

  /**
   * Level ID.
   */
  public id: string;

  /**
   * Revision ID.
   */
  public revision_id: string = '';

  /**
   * Content.
   */
  public content: any;

  /**
   * Comments.
   */
  public comments: string | null = null;

  /**
   * Status of level.
   */
  public status: string | null = null;

  /**
   * Screenshot of level, in base 64
   */
  public imageBase64: string | null = null;

  /**
   * Level created date.
   */
  public created_at: any = 'null';


  public fillFromFields (fields: any) {
    // TODO
  }
}
