import Vue from 'vue'
import App from '@/components/app/App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import moment from 'moment-timezone'
import VuetifyConfirm from 'vuetify-confirm'
// import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import VueDraggableResizable from 'vue-draggable-resizable'
import VueHtml2Canvas from 'vue-html2canvas'


Vue.config.productionTip = false

moment.locale('ru')
// moment.tz.setDefault('Europe/Moscow')

Vue.component('vue-draggable-resizable', VueDraggableResizable)

// Vue.use(VueTelInputVuetify, { vuetify })
Vue.use(VueHtml2Canvas)
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Подтвердить',
  buttonFalseText: 'Отмена',
  color: 'blue',
  icon: '',
  title: 'Подтвердите',
  width: 350,
  property: '$confirm'
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
