import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { LOG_IN_USER } from '@/store/root/actions'


@Component
export default class Login extends Vue {
  public login: string | null = null
  public password: string | null = null

  public errorMessage: string | null = ''
  public isLoading = false

  /**
   * Log in user.
   */
  @Action(LOG_IN_USER)
  public logInUser:
    /**
     * @param payload   User`s login and password.
     *
     * @return   Resolved promise.
     */
    (payload: { login: string, password: string }) => Promise<void>

  /**
   * Form submit handler.
   */
  public async formSubmit () {
    console.log('formSubmit')
    this.errorMessage = ''
    this.isLoading = true

    // todo: move to vue-validate rules
    if (!this.login) {
      this.errorMessage = 'Укажите логин'
      this.isLoading = false
      return
    }
    if (!this.password) {
      this.errorMessage = 'Укажите пароль'
      this.isLoading = false
      return
    }
    try {
      await this.logInUser({ login: this.login, password: this.password })
    } catch (error) {
      this.isLoading = false
      this.errorMessage = error.error === 'Not authorized'
        ? 'Пользователь не существует либо неверный пароль'
        : 'Ошибка авторизации'
      console.log(error)
      return
    }

    this.isLoading = false
    this.$router.push({ name: 'home' })
  }
}
