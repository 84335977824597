import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import ApiRequest from '@/core/ApiRequest'
import GameConstantsModel from '@/core/models/GameConstants'
import { ADD_GAME_CONSTANTS } from '@/store/root/actions'
import { GET_API_REQUEST } from '@/store/root/getters'

import GameConstantsHistory from '@/components/shared/game-constants-history/GameConstantsHistory.vue'


@Component({
  components: { GameConstantsHistory }
})
export default class GameConstants extends Vue {
  public revision_id: any = null
  public content: any = null
  public comments: string = ''

  public errorMessage: string = ''
  public isLoading = false;

  public constants: any = {
    GAME_MAX_LEVEL: '60',
    SLIDER_JOYSTICK_COLLISION_SIZE: '200',

    BALL_START_SPEED: '0.5',
    BALL_SPEED_ACCELERATION: '0.1',
    BALL_ACCELERATION_INTERVAL: '10',
    BALL_COLLISION_MIN_ANGLE: '15',
    BALL_COLLISION_MIN_ANGLE_CORRECTING_TO_ANGLE: '45',
    BALL_COLLISION_WALLS_MIN_SPEED: '0.1',
    BALL_COLLISION_PLATFORM_DELTA: '10',

    PORTAL_DONT_BOOST_IF_SPEED_GREATER_THEN: '0.5',
    PORTAL_BOOST_SPEED_TO: '1',

    BOOST_BOMB_COST_COINS: '9',
    BOOST_BOMB_EXPLOSION_SIZE: '130',
    BOOST_LIGHTNING_COST_COINS: '8',
    BOOST_LIGHTNING_DURATION_SECONDS: '10',
    BOOST_SPEED_UP_VELOCITY: '0.4',
    BOOST_SPEED_UP_DURATION_SECONDS: '10',
    BOOST_TIME_COST_COINS: '5',
    BOOST_HUMMER_COST_COINS: '7',

    HEART_INITIAL_COUNT: '10',
    LEVEL_MAX_HEART_COUNT: '2',
    HEART_SPAWN_TIME_SECONDS: '300',
    HEART_SHOP_BUY_5_COST: '6',
    HEART_SHOP_BUY_10_COST: '12',
    HEART_SHOP_BUY_15_COST: '17',

  }


  /**
   * Add game constants.
   */
  @Action(ADD_GAME_CONSTANTS)
  public addGameConstants:
    /**
     * @param constants   Constants to add.
     *
     * @return   Resolved promise.
     */
    (gameConstants: GameConstantsModel) => Promise<void>

  /**
   * Get api request object, based on global Vuex state.
   */
  @Getter(GET_API_REQUEST)
  public getApiRequest: ApiRequest

  /**
   * Form submit handler.
   */
  public async formSubmit () {
    this.errorMessage = ''
    this.isLoading = true

    const gameConstants = new GameConstantsModel()
    gameConstants.comments = this.comments
    gameConstants.content = {
      Constants: this.constants
    }

    try {
      await this.addGameConstants(gameConstants)
      this.isLoading = false

      console.log('goto self')
      // this.$router.go(0)
      const href = this.$router.resolve({ name: 'gameConstants' }).href
      window.location.assign(href)
    } catch (error) {
      this.isLoading = false
    }
  }

  /**
   * Fetch current constants and fill component inputs.
   */
  public async fetchCurrentConstants () {
    const response = await this.getApiRequest.send(
      'GET',
      'constants/' +
      (this.revision_id ? '/' + encodeURIComponent(this.revision_id!) : ''),
    )
    if (!response || !response.data) { return }

    const gameConstants = response.data
    this.content = gameConstants.content
    this.comments = gameConstants.comments
    this.revision_id = gameConstants.revision_id

    const constants = gameConstants.content.Constants
    for (const k in constants) {
      this.constants[k] = constants[k] || this.constants[k]
    }
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    this.revision_id = this.$route.params.revision
    await this.fetchCurrentConstants()
  }
}
