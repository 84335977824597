import { Component, Vue } from 'vue-property-decorator'

import Levels from '@/components/shared/levels/Levels.vue'


@Component({
  components: { Levels }
})
export default class Home extends Vue {

}
