import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'

import ApiRequest from '@/core/ApiRequest'
import { LevelStatus } from '@/core/models/Level'
import { GET_API_REQUEST, IS_HISTORY_DIALOG_SHOWN } from '@/store/root/getters'
import { SET_HISTORY_DIALOG_SHOWN } from '@/store/root/mutations'


@Component
export default class LevelHistory extends Vue {
  public LevelStatus: any = LevelStatus;

  public history: Array<any> = []

  /**
   * Property that indicates ID of shown level.
   */
  @Prop({ default: null })
  public id: string | null;

  /**
   * Property that indicates revision of shown level.
   */
  @Prop({ default: null })
  public revision_id: string | null;

  /**
   * Get api request object, based on global Vuex state.
   */
  @Getter(GET_API_REQUEST)
  public getApiRequest: ApiRequest

  /**
   * Returns statuses list.
   */
  public getStatusesNames (): {[key: string]: any} {
    const names: any = {}
    names[LevelStatus.INPROGRESS] = 'В работе'
    names[LevelStatus.COMPLETED] = 'Готов'
    return names
  }

  /**
   * Fetch history of current level.
   */
  public async fetchLevelHistory () {
    if (!this.id) { return }

    const response = await this.getApiRequest.send(
      'GET',
      'levels/' + encodeURIComponent(this.id) + '/history',
    )
    if (!response || !response.data) { return }

    this.history = response.data
  }

  /**
   * Format date to local format.
   */
  public formatDate (date: string): string {
    return (new Date(date)).toLocaleDateString() + ' ' + (new Date(date)).toLocaleTimeString()
  }

  /**
   * Navigate to given revision.
   */
  public navigateToRevision (revisionId: string): void {
    const href = this.$router.resolve({ name: 'showLevel', params: { id: this.id!, revision: revisionId } }).href
    window.location.assign(href)
  }

  /**
   * Get formatted field.
   *
   * @param field  Field name.
   */
  public getFormattedField (field: string): string {
    const fieldNames: any = {
      comments: 'Комментарии',
      status: 'Статус',
    }
    return fieldNames[field] || ''
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    await this.fetchLevelHistory()
  }
}
