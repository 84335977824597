import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import html2canvas from 'html2canvas'

import ApiRequest from '@/core/ApiRequest'
import LevelModel, { LevelStatus } from '@/core/models/Level'
import Cookie, { CookieOrigin } from '@/core/models/level/Cookie'
import CookiePlaceholder from '@/core/models/level/CookiePlaceholder'
import { ADD_LEVEL_ITEM } from '@/store/root/actions'
import { GET_API_REQUEST, IS_HISTORY_DIALOG_SHOWN } from '@/store/root/getters'
import { SET_HISTORY_DIALOG_SHOWN } from '@/store/root/mutations'

import LevelHistory from '@/components/shared/level-history/LevelHistory.vue'


@Component({
  components: { LevelHistory }
})
export default class Level extends Vue {
  public COOKIE_WIDTH = 70 // TODO: move to CookieModel
  public COOKIE_HEIGHT = 67
  public GRID_X = 85
  public GRID_Y = 82
  public GRID_OFFSET_X = 34 - 4 * this.GRID_X / 10
  public GRID_OFFSET_Y = 24.6 - 3 * this.GRID_Y / 10

  public CONTAINER_WIDTH = 640
  public CONTAINER_OFFSET_Y = 350
  public PLACEHOLDER_MARGIN_X = this.GRID_X / 10
  public PLACEHOLDER_WIDTH = 170
  public PLACEHOLDER_HEIGHT = 500

  public placeholderTopOffset = 0

  public revision_id: any = null
  public content: any = null
  public contentHeight: number = 0
  public comments: string = ''
  public status: LevelStatus | null = null
  public maxTime: string = '90'
  public bombAvailable = false // BOOST_BOMB_AVAILABLE
  public lightningAvailable = false // BOOST_LIGHTNING_AVAILABLE
  public timeAvailable = false // BOOST_TIME_AVAILABLE
  public hummerAvailable = false // BOOST_HUMMER_AVAILABLE

  public LevelStatus = LevelStatus
  public CookieOrigin = CookieOrigin
  public errorMessage: string = ''
  public isLoading = false;
  public canEdit = true;

  public zOrderOn : string|null = null;

  public imgOutput = '';

  public placeholders: CookiePlaceholder[] = [
    new CookiePlaceholder('cooki_0.png', 0, 0),
    new CookiePlaceholder('cooki_1.png', 0, 1),
    new CookiePlaceholder('cooki_2.png', 0, 2),
    new CookiePlaceholder('cooki_3.png', 0, 3),
    new CookiePlaceholder('cooki_4.png', 0, 4),
    new CookiePlaceholder('cooki_5.png', 0, 5),
    new CookiePlaceholder('cooki_6.png', 1, 0),
    new CookiePlaceholder('cooki_7.png', 1, 1),
    new CookiePlaceholder('cooki_8.png', 1, 2),
    new CookiePlaceholder('cooki_9.png', 1, 3),
    new CookiePlaceholder('cooki_10.png', 1, 4),
    new CookiePlaceholder('star_in game.png', 1, 5),
  ]

  public cookies: Cookie[] = []

  /**
   * Registered references of this component child elements, that will be used
   * in component logic.
   */
  public readonly $refs: {
    cookieElements: any[],
    levelArea: any,
  };

  /**
   * Property that indicates ID of shown level.
   */
  @Prop({ default: null })
  public id: string | null;

  /**
   * Property that indicates revision of shown level.
   */
  @Prop({ default: null })
  public revision: string | null;

  /**
   * Property that indicates is edit mode enabled.
   */
  @Prop({ default: true })
  public editable: boolean;

  /**
   * Add level item.
   */
  @Action(ADD_LEVEL_ITEM)
  public addLevelItem:
    /**
     * @param level   Level to add.
     *
     * @return   Resolved promise.
     */
    (level: LevelModel) => Promise<void>

  /**
   * Get api request object, based on global Vuex state.
   */
  @Getter(GET_API_REQUEST)
  public getApiRequest: ApiRequest

  /**
   * Get is history dialog shown flag, based on global Vuex state.
   */
  @Getter(IS_HISTORY_DIALOG_SHOWN)
  public isHistoryDialogShown: boolean

  /**
   * Sets is history dialog shown flag state.
   */
  @Mutation(SET_HISTORY_DIALOG_SHOWN)
  public setHistoryDialogShown: (isHistoryDialogShown: boolean) => void

  /**
   * Returns statuses list.
   */
  public getStatusesList () {
    return [
      { status: LevelStatus.COMPLETED, title: 'Готов' }, // TODO: move to models
      { status: LevelStatus.INPROGRESS, title: 'Требует доработки' },
    ]
  }

  /**
   * Form submit handler.
   */
  public async formSubmit () {
    // console.log('formSubmit')
    this.errorMessage = ''
    this.isLoading = true

    const level = new LevelModel()
    if (this.id) {
      level.id = this.id
    }
    level.comments = this.comments
    level.status = this.status

    const objects = []
    this.updateZOrders() // TODO: remove if need manual fix zOrder

    const cookies = this.getCookiesOnLevel()
    for (const cookie of cookies) {
      objects.push({
        NAME: String(cookie.getObjectName()),
        TYPE: String(cookie.getObjectType()),
        ZORDER: String(cookie.zOrder),
        cImage: {
          SRC: cookie.type,
          POSX: String(cookie.getNewX()),
          POSY: String(cookie.getNewY()),
          SIZEX: String(this.COOKIE_WIDTH),
          SIZEY: String(this.COOKIE_HEIGHT),
          ZORDER: String(cookie.zOrder),
        }
      })
    }
    level.content = {
      root: {
        Zone: {
          Object: objects,
          TIME: this.maxTime,
          BOOST_BOMB_AVAILABLE: this.bombAvailable,
          BOOST_LIGHTNING_AVAILABLE: this.lightningAvailable,
          BOOST_TIME_AVAILABLE: this.timeAvailable,
          BOOST_HUMMER_AVAILABLE: this.hummerAvailable
        }
      }
    }

    level.imageBase64 = await this.getScreenshot()

    // console.log('content=', level.content)

    try {
      await this.addLevelItem(level)
      this.isLoading = false

      if (!this.id) {
        console.log('goto home')
        this.$router.push({ name: 'home' })
      } else {
        console.log('goto self')
        // this.$router.go(0)
        const href = this.$router.resolve({ name: 'showLevel', params: { id: this.id! } }).href
        window.location.assign(href)
      }
    } catch (error) {
      this.isLoading = false
    }
  }

  /**
   * Fetch current level and fill component inputs.
   */
  public async fetchCurrentLevel () {
    console.log(`fetchCurrentLevel, id=${this.id}, revision=${this.revision}`)
    const response = await this.getApiRequest.send(
      'GET',
      'levels/' + encodeURIComponent(this.id!) +
      (this.revision ? '/' + encodeURIComponent(this.revision!) : ''),
    )
    if (!response || !response.data) { return }

    const level = response.data
    this.content = level.content
    this.contentHeight = this.getContentHeight()

    this.cookies = []
    for (const item of level.content.root.Zone.Object) {
      if (parseFloat(item.cImage.ZORDER) > 0) {
        this.zOrderOn = 'true'
      }
      this.cookies.push(new Cookie(item.cImage.SRC, parseFloat(item.cImage.POSX), parseFloat(item.cImage.POSY), parseFloat(item.cImage.ZORDER)))
    }

    for (const placeholder of this.placeholders) {
      this.cookies.push(new Cookie(
        placeholder.type,
        placeholder.leftOffsetIndex * this.GRID_X + this.CONTAINER_WIDTH +
          this.PLACEHOLDER_MARGIN_X + this.COOKIE_WIDTH / 2 + 2 * this.GRID_X / 10 - 6,
        this.contentHeight - this.CONTAINER_OFFSET_Y - this.COOKIE_HEIGHT / 2 -
          placeholder.topOffsetIndex * this.GRID_Y - 2 * this.GRID_Y / 10 - 3,
        0,
        CookieOrigin.PLACEHOLDER
      ))
    }
    this.comments = level.comments
    this.status = level.status
    this.revision_id = level.revision_id

    this.maxTime = level.content.root.Zone.TIME || '90'
    this.bombAvailable = level.content.root.Zone.BOOST_BOMB_AVAILABLE || false
    this.lightningAvailable = level.content.root.Zone.BOOST_LIGHTNING_AVAILABLE || false
    this.timeAvailable = level.content.root.Zone.BOOST_TIME_AVAILABLE || false
    this.hummerAvailable = level.content.root.Zone.BOOST_HUMMER_AVAILABLE || false
  }

  /**
   * Show level history.
   */
  public showHistory () {
    this.setHistoryDialogShown(true)
  }

  public getContentHeight (): number {
    let maxTop = 0
    for (const item of this.content.root.Zone.Object) {
      maxTop = maxTop < parseFloat(item.cImage.POSY) ? parseFloat(item.cImage.POSY) : maxTop
    }
    maxTop = maxTop < 300 ? 300 : maxTop
    return maxTop + 550
  }

  public onDragStop (x: any, y: any, cookie: Cookie) {
    // console.log(`onDragStopTop(${x}, ${y})`)
    if (cookie.origin === CookieOrigin.PLACEHOLDER) {
      const originalCookie = new Cookie(cookie.type, cookie.x, cookie.y, cookie.zOrder, cookie.origin)
      const newCookie = new Cookie(cookie.type, cookie.x + x, cookie.y - y - this.placeholderTopOffset, cookie.zOrder, CookieOrigin.LEVEL)
      this.cookies = this.cookies.filter(item => item.id !== cookie.id)

      this.cookies.push(newCookie)
      // console.log(newCookie)
      this.cookies.push(originalCookie)
    }
    this.$nextTick(() => {
      this.updateZOrders()
    })
  }

  public handleScroll () {
    const bodyRect = document.body.getBoundingClientRect()
    const headerRect = document.querySelector('header')!.getBoundingClientRect()
    const elemRect = document.getElementById('level-area')!.getBoundingClientRect()
    const containerTopOffset = elemRect.top - bodyRect.top - headerRect.height
    const delta = window.pageYOffset - containerTopOffset
    const minPart = this.GRID_Y / 5
    if (delta < minPart) return
    const topOffset = Math.trunc(delta / minPart) * minPart
    if (topOffset > this.contentHeight - this.PLACEHOLDER_HEIGHT) return
    this.placeholderTopOffset = topOffset
    // console.log(`handleScroll, delta=${delta}, offset=${this.placeholderTopOffset} containerTopOffset=${containerTopOffset}`)
  }

  public getTopOf (cookie: Cookie) {
    return this.contentHeight - this.CONTAINER_OFFSET_Y - cookie.y - this.COOKIE_HEIGHT / 2 +
      (cookie.origin === CookieOrigin.PLACEHOLDER ? this.placeholderTopOffset : 0)
  }

  public getLeftOf (cookie: Cookie) {
    return cookie.x - this.COOKIE_WIDTH / 2
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    this.canEdit = this.editable
    if (this.id) {
      await this.fetchCurrentLevel()
    }
    window.addEventListener('scroll', this.handleScroll)
  }

  /**
   * Destroyed lifecycle hook. Fires when component instance is destroyed.
   */
  public async destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  }


  public async getScreenshot () {
    const options = {
      type: 'dataURL',
      width: this.CONTAINER_WIDTH,
      height: this.$refs.levelArea.offsetHeight > 1200
        ? this.$refs.levelArea.offsetHeight - 310
        : this.$refs.levelArea.offsetHeight,
      x: 0,
      y: this.$refs.levelArea.offsetHeight > 1200 ? 150 : 0,
      scale: 0.5,
    }
    const canvas = await html2canvas(this.$refs.levelArea, options)
    return canvas.toDataURL('image/jpeg', 1.5)
    // this.imgOutput = canvas.toDataURL('image/jpeg', 0.8)
  }

  public alignToGrid () {
    const X0 = -19.5
    const Y0 = 20.7
    for (const key in this.cookies) {
      const cookie = this.cookies[key]
      const x = cookie.x
      const y = cookie.y
      cookie.x = Math.round((x - X0) / (this.GRID_X / 5)) * this.GRID_X / 5 + X0
      cookie.y = Math.round((y - Y0) / (this.GRID_Y / 5)) * this.GRID_Y / 5 + Y0
      this.$set(this.cookies, key, cookie)
    }
  }

  public moveCookie (dir: string) {
    for (const key in this.cookies) {
      const cookie = this.cookies[key]
      if (cookie.origin === CookieOrigin.PLACEHOLDER) {
        continue
      }
      if (dir === 'bottom') {
        cookie.y = cookie.y - this.GRID_Y
      }
      if (dir === 'top') {
        cookie.y = cookie.y + this.GRID_Y
      }
      if (dir === 'right') {
        cookie.x = cookie.x + this.GRID_X
      }
      if (dir === 'left') {
        cookie.x = cookie.x - this.GRID_X
      }
      this.$set(this.cookies, key, cookie)
    }
  }

  public getCookiesOnLevel (): any {
    const objects = []
    for (const cookie of this.cookies) {
      if (cookie.origin === CookieOrigin.PLACEHOLDER) {
        continue
      }
      // const element = this.$refs.cookieElements[index].$el
      const element = document.querySelector(`.cookie[data-id="${cookie.id}"]`)
      if (!element) {
        continue
      }
      const matrix = cookie.getElementMatrix()
      if (!matrix) {
        continue
      }
      const left = this.getLeftOf(cookie) + matrix.m41
      const top = this.getTopOf(cookie) + matrix.m42
      // console.log(`new=(${cookie.getNewX()}, ${cookie.getNewY()}), left=${left}, top=${top}, width=${this.CONTAINER_WIDTH}, height=${this.contentHeight} `)
      if (left < 0 || left > this.CONTAINER_WIDTH - this.COOKIE_WIDTH) {
        // console.log('Left position is outbound. Skip.')
        continue
      }
      if (top < 0 || top > this.contentHeight - this.COOKIE_HEIGHT) {
        // console.log('Top position is outbound. Skip.')
        continue
      }
      objects.push(cookie)
    }
    return objects
  }

  public updateZOrders () {
    const cookies = this.getCookiesOnLevel()
    if (!this.zOrderOn) {
      for (const cookie of cookies) {
        const index = this.cookies.findIndex(({ id }) => id === cookie.id)
        if (index >= 0) {
          this.cookies[index].zOrder = 0
        }
      }
      return
    }

    const minItems = cookies.map((c: Cookie) => c.getNewY())
    // console.log('minItems=', minItems)
    const minY = Math.min(...minItems)
    // console.log(`MinY=${minY}`)
    for (const cookie of cookies) {
      const index = this.cookies.findIndex((c: Cookie) => (c.id === cookie.id))
      if (index < 0) {
        continue
      }

      const precision = 16
      this.cookies[index].zOrder = Math.floor((cookie.getNewY() - minY + precision) / this.GRID_Y)
    }
  }

  /**
   * Recalculate z order of all cookies.
   */
  @Watch('zOrderOn')
  public onChangeZOrderOn () {
    this.$nextTick(() => {
      this.updateZOrders()
    })
  }
}
