import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'

import UserModel, { Role } from '@/core/models/User'
import { ADD_USER_ITEM, FETCH_USERS_LIST, UPDATE_USER_ITEM } from '@/store/root/actions'
import { GET_USERS_LIST, IS_USER_DIALOG_SHOWN } from '@/store/root/getters'
import { SET_USER_DIALOG_SHOWN } from '@/store/root/mutations'


@Component
export default class Users extends Vue {
  public user = new UserModel()

  public errorMessage = ''

  public isLoading = false

  public showPassword = false

  public showPassField = false

  public rules: any = {
    required: (value: any) => !!value || 'Требуется указать',
    min: (v: any) => (v && v.length >= 8) || 'Минимум 8 символов',
    min3: (v: any) => (v && v.length >= 3) || 'Минимум 3 символа',
    valid_email: (email: any) => (email && this.isEmailValid(email)) || 'Неверный емейл',
  }

  /**
   * Property that indicates ID of changed user.
   */
  @Prop({ default: null })
  public id: string | null;

  /**
   * Property that indicates mode of dialog: `add` or `change`
   */
  @Prop({ default: 'add' })
  public mode: string;

  /**
   * Get is user dialog shown flag, based on global Vuex state.
   */
  @Getter(IS_USER_DIALOG_SHOWN)
  public isUserDialogShown: boolean

  /**
   * Sets is user dialog shown flag state.
   */
  @Mutation(SET_USER_DIALOG_SHOWN)
  public setUserDialogShown: (isUserDialogShown: boolean) => void

  /**
   * Fetch users list.
   */
  @Action(FETCH_USERS_LIST)
  public fetchUsersList: () => void;

  /**
   * Get users list, based on global Vuex state.
   */
  @Getter(GET_USERS_LIST)
  public getUsersList: UserModel[]

  /**
   * Add user item.
   */
  @Action(ADD_USER_ITEM)
  public addUserItem:
    /**
     * @param user   User to add.
     *
     * @return   Resolved promise.
     */
    (user: UserModel) => Promise<void>

  /**
   * Update user item.
   */
  @Action(UPDATE_USER_ITEM)
  public updateUserItem:
    /**
     * @param user   User to update.
     *
     * @return   Resolved promise.
     */
    (user: UserModel) => Promise<void>

  public get isFormValid (): boolean {
    if (!this.user) return false
    if (!this.user.username || this.user.username!.length < 3) return false
    if (!this.user.fullname) return false
    if (!this.user.email || !this.isEmailValid(this.user.email)) return false
    if (this.mode === 'add') {
      if (!this.user.password || this.user.password!.length < 8) return false
    }
    if (this.mode === 'change') {
      if (this.showPassField && (!this.user.password || this.user.password!.length < 8)) {
        return false
      }
    }
    return true
  }

  /**
   * Create new user.
   */
  public async addUser () {
    console.log('adduser')
    this.errorMessage = ''
    this.isLoading = true
    try {
      await this.addUserItem(this.user!)
      await this.fetchUsersList()
    } catch (error) {
      this.isLoading = false
      this.errorMessage = 'При создании пользователя произошла ошибка, попробуйте снова '
      console.log(error)
      return
    }

    this.isLoading = false
    this.$router.go(0)
  }

  /**
   * Change user.
   */
  public async updateUser () {
    console.log('update User')
    this.errorMessage = ''
    this.isLoading = true
    try {
      if (!this.showPassField) {
        this.user!.password = null
      }
      await this.updateUserItem(this.user!)
      await this.fetchUsersList()
    } catch (error) {
      this.isLoading = false
      this.errorMessage = 'При редактировании пользователя произошла ошибка, попробуйте снова '
      console.log(error)
      return
    }

    this.isLoading = false
    this.setUserDialogShown(false)
  }

  /**
   * Email validator.
   *
   * @param email
   * @private
   */
  private isEmailValid (email: string): boolean {
    return !!(String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ))
  }

  /**
   * Watch value of dialog`s shown state.
   *
   * @param value   New state value.
   */
  @Watch('isUserDialogShown')
  public onShownChanged (value: boolean) {
    console.log('isUserDialogShown changed!!!')
    if (value === false) {
      return
    }
    if (this.mode === 'add') {
      this.user = new UserModel()
    }
    if (this.mode === 'change' && this.id) {
      const user = this.getUsersList.find((user) => user.id === this.id)
      this.user = new UserModel()
      if (user) {
        this.user.id = user.id
        this.user.username = user.username
        this.user.fullname = user.fullname
        this.user.email = user.email
      }
    }
  }
}
