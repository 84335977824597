/**
 * Cookie of placeholder with details.
 */
export default class CookiePlaceholder {
  /**
   * Type of cookie.
   */
  public type: string;

  /**
   * Left offset index of cookie in panel.
   */
  public leftOffsetIndex: number;

  /**
   * Top offset index of cookie in panel.
   */
  public topOffsetIndex: number;

  /**
   * Initializes cookie of placeholder with given required properties.
   *
   * @param type              Type of cookie.
   * @param leftOffsetIndex   Left offset index of cookie in panel.
   * @param topOffsetIndex    Top offset index of cookie in panel.
   */
  public constructor (
    type: string,
    leftOffsetIndex: number,
    topOffsetIndex: number,
  ) {
    this.type = type
    this.leftOffsetIndex = leftOffsetIndex
    this.topOffsetIndex = topOffsetIndex
  }
}
