import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import ApiRequest from '@/core/ApiRequest'
import LevelModel, { LevelStatus } from '@/core/models/Level'
import { CHANGE_LEVELS_ORDER } from '@/store/root/actions'
import { GET_API_REQUEST } from '@/store/root/getters'

import Draggable from 'vuedraggable'


@Component({
  components: { Draggable }
})
export default class Levels extends Vue {
  public LevelStatus: any = LevelStatus

  public levels: Array<any> = []

  public orderChanged: boolean = false
  public isLoading = false;

  public colCount = 6;

  /**
   * Get api request object, based on global Vuex state.
   */
  @Getter(GET_API_REQUEST)
  public getApiRequest: ApiRequest

  /**
   * Change levels order.
   */
  @Action(CHANGE_LEVELS_ORDER)
  public changeLevelsOrder:
    /**
     * @param levels   Levels.
     *
     * @return   Resolved promise.
     */
    (levels: any[]) => Promise<void>

  public gotoLevel (levelId: string) {
    this.$router.push({ name: 'showLevel', params: { id: levelId } })
  }

  public addNewLevel () {
    const thisDayDate = new Date(this.$route.params.date)
    this.$router.push({
      name: 'addLevel',
    })
  }

  /**
   * Format date to local format.
   */
  public formatDate (date: string): string {
    return (new Date(date)).toLocaleDateString() + ' ' + (new Date(date)).toLocaleTimeString()
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    const response = await this.getApiRequest.send('GET', 'levels')
    if (!response || !response.data) {
      return []
    }
    this.levels = response.data
    console.log(response.data)
    if (this.$route.query.small) {
      this.colCount = 12
    }
  }


  /**
   * Returns options for Draggable component.
   *
   * @return  Object with options.
   */
  public get draggableOptions (): any {
    return {
      animation: 0,
      delay: undefined,
      // disabled: this.currentTab === ConversationListTab.ALL,
      disabled: false,
      fallbackAxis: 'y',
      forceFallback: true,
      scrollSensitivity: 50,
    }
  }

  // /**
  //  *  Returns list of sorted level.
  //  */
  // public get sortedLevels () {
  //   return this.levels
  // }
  //
  // /**
  //  * Update conversations order. Called from Draggable component on
  //  * changing conversations list order. Only for favourites list.
  //  *
  //  * @param conversations  List of conversations.
  //  */
  // public set sortedLevels (levels: any[]) {
  //   this.changeOrder(levels)
  // }

  public setSortActivated (isActivated: boolean) {
    // console.log(`setSortActivated(${isActivated})`)
    if (!isActivated) {
      this.orderChanged = true
    }
  }

  // public changeOrder (levels: any[]) {
  //   console.log('changeOrder!')
  // }

  public async saveChanges (levels: any[]) {
    this.isLoading = true
    try {
      await this.changeLevelsOrder(this.levels)
      this.isLoading = false
      this.orderChanged = false

      const href = this.$router.resolve({ name: 'home' }).href
      window.location.assign(href)
    } catch (error) {
      this.isLoading = false
    }
  }

  public removeLevel (levelUqId: string) {
    console.log(`removeLevel${levelUqId}`)
    const index = this.levels.findIndex(level => level.uq_id === levelUqId)
    if (index < 0) {
      return
    }
    this.orderChanged = true
    this.levels.splice(index, 1)
  }

  public cloneLevel (levelUqId: string) {
    console.log(`cloneLevel${levelUqId}`)
    const index = this.levels.findIndex(level => level.uq_id === levelUqId)
    if (index < 0) {
      return
    }

    this.orderChanged = true
    let uq_id: string | null = null
    while (uq_id === null ||
      this.levels.findIndex(level => level.uq_id === uq_id) >= 0
    ) {
      uq_id = '#' + Math.floor(Math.random() * 99999) + ':' +
        this.levels[index].uq_id.replace(/^#\d+:/, '')
    }
    console.log(`new uq_id=${uq_id}`)

    const newElement = { ...this.levels[index], uq_id }
    this.levels.splice(index + 1, 0, newElement)
  }
}
