/**
 * Game constants with details.
 */
export default class GameConstants {
  /**
   * Unique ID.
   */
  public uq_id: string;

  /**
   * Revision ID.
   */
  public revision_id: string = '';

  /**
   * Content.
   */
  public content: any;

  /**
   * Comments.
   */
  public comments: string | null = null;

  /**
   * Object created date.
   */
  public created_at: any = 'null';


  public fillFromFields (fields: any) {
    // TODO
  }
}
