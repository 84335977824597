import { MutationTree } from 'vuex'

import ApiRequest from '@/core/ApiRequest'
import AccessModel from '@/core/models/AccessModel'
import RootState from '@/store/root/state'


export const SET_LOADING: string = 'setLoading'

export const SET_USER_DIALOG_SHOWN: string = 'setUserDialogShown'

export const SET_HISTORY_DIALOG_SHOWN: string = 'setHistoryDialogShown'

export const SET_ACCESS_MODEL = 'setAccessModel'

export const SET_API_REQUEST = 'setApiRequest'

/**
 * Sets is search loading flag state.
 */
export function setLoading (state: RootState, isLoading: boolean) {
  state.isLoading = isLoading
}

/**
 * Sets is user dialog shown flag state.
 */
export function setUserDialogShown (state: RootState, isUserDialogShown: boolean) {
  state.isUserDialogShown = isUserDialogShown
}

/**
 * Sets is history dialog shown flag state.
 */
export function setHistoryDialogShown (state: RootState, isHistoryDialogShown: boolean) {
  state.isHistoryDialogShown = isHistoryDialogShown
}

/**
 * Sets accessModel.
 *
 * @param state   Root Vuex state.
 * @param model   Access model.
 */
export function setAccessModel (state: RootState, model: AccessModel | null) {
  state.accessModel = model
}

/**
 * Sets apiRequest.
 *
 * @param state     Root Vuex state.
 * @param instance  ApiRequest instance.
 */
export function setApiRequest (state: RootState, instance: ApiRequest) {
  state.apiRequest = instance
}

export default {
  setLoading,
  setAccessModel,
  setApiRequest,
  setUserDialogShown,
  setHistoryDialogShown,
} as MutationTree<RootState>
