import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { PASSWORD_CHANGE_REQUEST } from '@/store/root/actions'


@Component
export default class ForgetPassword extends Vue {
  public login: string = ''

  public errorMessage: string | null = ''
  public isLoading = false
  public requestCreated = false

  /**
   * Create password change request.
   */
  @Action(PASSWORD_CHANGE_REQUEST)
  public passwordChangeRequest:
    /**
     * @param login   Login of user.
     *
     * @return   Resolved promise.
     */
    (login: string) => Promise<void>

  /**
   * Form submit handler.
   */
  public async formSubmit () {
    console.log('formSubmit')
    this.errorMessage = ''
    this.isLoading = true

    // todo: move to vue-validate rules
    if (!this.login || this.login === '') {
      this.errorMessage = 'Укажите логин'
      this.isLoading = false
      return
    }
    try {
      await this.passwordChangeRequest(this.login)
      this.requestCreated = true
    } catch (error) {
      this.isLoading = false
      this.errorMessage = error.error === "User doesn't exist"
        ? 'Пользователь не существует'
        : 'Ошибка, попробуйте еще раз'
      console.log(error)
      return
    }

    this.isLoading = false
    // this.$router.push({ name: 'home' })
  }
}
