import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { PASSWORD_SET_FOR_REQUEST } from '@/store/root/actions'


@Component
export default class SetPassword extends Vue {
  public password: string = '' // TODO: add rules

  public errorMessage: string | null = ''
  public isLoading = false
  public passwordChanged = false

  /**
   * Set password by request.
   */
  @Action(PASSWORD_SET_FOR_REQUEST)
  public passwordSetForRequest:
    /**
     * @param payload   Token and new password.
     *
     * @return   Resolved promise.
     */
    (payload: { token: string, password: string }) => Promise<void>

  /**
   * Form submit handler.
   */
  public async formSubmit () {
    console.log('formSubmit')
    this.errorMessage = ''
    this.isLoading = true

    // todo: move to vue-validate rules
    const token: string = this.$route.query.token as string
    if (!token || token === '') {
      this.errorMessage = 'Некорректные параметры запроса'
      this.isLoading = false
      return
    }
    if (!this.password || this.password === '') {
      this.errorMessage = 'Укажите пароль'
      this.isLoading = false
      return
    }
    if (this.password.length < 8) {
      this.errorMessage = 'Минимальная длина пароля 8 символов'
      this.isLoading = false
      return
    }
    try {
      await this.passwordSetForRequest({ token, password: this.password })
      this.passwordChanged = true
    } catch (error) {
      this.isLoading = false
      this.errorMessage = error.error === "User doesn't exist"
        ? 'Пользователь не существует'
        : 'Ошибка, попробуйте еще раз'
      console.log(error)
      return
    }

    this.isLoading = false
  }
}
