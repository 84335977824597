import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import store from '@/store'
import { GET_ACCESS_MODEL } from '@/store/root/getters'

import Home from '@/components/views/home/Home.vue'
import AddLevel from '@/components/views/add-level/AddLevel.vue'
import Login from '@/components/views/login/Login.vue'
import ShowLevel from '@/components/views/show-level/ShowLevel.vue'
import Users from '@/components/views/users/Users.vue'
import ForgetPassword from '@/components/views/forget-password/ForgetPassword.vue'
import SetPassword from '@/components/views/set-password/SetPassword.vue'
import GameConstants from '@/components/views/game-constants/GameConstants.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/users',
    name: 'users',
    component: Users
  },
  {
    path: '/add-level',
    name: 'addLevel',
    component: AddLevel
  },
  {
    path: '/forget-password',
    name: 'forgetPassword',
    component: ForgetPassword
  },
  {
    path: '/set-password',
    name: 'setPassword',
    component: SetPassword
  },
  {
    path: '/levels/:id/:revision*',
    name: 'showLevel',
    component: ShowLevel,
    // component: () => import(/* webpackChunkName: "about" */ '@/components/views/about/About.vue')
  },
  {
    path: '/game-constants/:revision*',
    name: 'gameConstants',
    component: GameConstants,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const skipAuthPages = ['login', 'forgetPassword', 'setPassword']
  if (!skipAuthPages.includes(to.name || 'home') && !store.getters[GET_ACCESS_MODEL]) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
