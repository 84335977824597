import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'

import UserChangeDialog from '@/components/shared/user-change-dialog/UserChangeDialog.vue'

import AccessModel from '@/core/models/AccessModel'
import UserModel, { Role } from '@/core/models/User'
import { LOCK_USER, UNLOCK_USER, FETCH_USERS_LIST } from '@/store/root/actions'
import { GET_ACCESS_MODEL, GET_USERS_LIST, IS_USER_DIALOG_SHOWN } from '@/store/root/getters'
import { SET_USER_DIALOG_SHOWN, SET_LOADING } from '@/store/root/mutations'


@Component({
  components: { UserChangeDialog }
})
export default class Users extends Vue {
  public userDialogMode = 'add'

  public userDialogId: string | null = null

  /**
   * Get is user dialog shown flag, based on global Vuex state.
   */
  @Getter(IS_USER_DIALOG_SHOWN)
  public isUserDialogShown: boolean

  /**
   * Sets is loading flag state.
   */
  @Mutation(SET_LOADING)
  public setLoading: (isLoading: boolean) => void

  /**
   * Sets is user dialog shown flag state.
   */
  @Mutation(SET_USER_DIALOG_SHOWN)
  public setUserDialogShown: (isUserDialogShown: boolean) => void

  /**
   * Get access model, based on global Vuex state.
   */
  @Getter(GET_ACCESS_MODEL)
  public getAccessModel: AccessModel | null

  /**
   * Get users list, based on global Vuex state.
   */
  @Getter(GET_USERS_LIST)
  public getUsersList: any

  /**
   * Fetch users list.
   */
  @Action(FETCH_USERS_LIST)
  public fetchUsersList: () => void;


  /**
   * Lock user item.
   */
  @Action(LOCK_USER)
  public lockUserAction:
    /**
     * @param user   User to lock.
     *
     * @return   Resolved promise.
     */
    (user: UserModel) => Promise<void>

  /**
   * Lock user item.
   */
  @Action(UNLOCK_USER)
  public unlockUserAction:
    /**
     * @param user   User to unlock.
     *
     * @return   Resolved promise.
     */
    (user: UserModel) => Promise<void>

  /**
   * Show add user dialog.
   */
  public addUser () {
    this.userDialogMode = 'add'
    this.userDialogId = null
    this.setUserDialogShown(true)
  }

  /**
   * Show change user dialog.
   */
  public changeUser (userId: string) {
    this.userDialogMode = 'change'
    this.userDialogId = userId
    this.setUserDialogShown(true)
  }

  /**
   * Lock given user.
   *
   * @param user   User to lock.
   */
  public async lockUser (user: UserModel) {
    if (await this.$confirm(`Заблокировать пользователя '${user.fullname}' ?`)) {
      this.setLoading(true)
      try {
        await this.lockUserAction(user)
        await this.fetchUsersList()
        this.setLoading(false)
      } catch (e) {
        this.setLoading(false)
      }
    }
  }

  /**
   * Lock given user.
   *
   * @param user   User to lock.
   */
  public async unlockUser (user: UserModel) {
    if (await this.$confirm(`Разблокировать пользователя '${user.fullname}' ?`)) {
      this.setLoading(true)
      try {
        await this.unlockUserAction(user)
        await this.fetchUsersList()
        this.setLoading(false)
      } catch (e) {
        this.setLoading(false)
      }
    }
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    if (this.getAccessModel && this.getAccessModel.user &&
      this.getAccessModel.user.role === Role.administrator
    ) {
      await this.fetchUsersList()
    }
  }
}
